.background {
    width: 200px !important;
    border-top: 14px solid black;
    width: 200px !important;
    margin-left: 50px;
}

.videofix {
    margin-bottom: 8px;
}
.separation {
    flex-direction: column;
    align-items: right;
}
.separation>* {
    margin-left: 4px;
    margin-right: 4px;
}

.spaceme {
    margin-left: 4px !important;
    margin-right: 4px !important;
}

.centered {
    text-align: center;
}

.spacer {
    flex: '1 1 100%',
}