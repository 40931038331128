.FlowMapParent {
  flex-direction:row;
 
}
.FlowMap {
    background-color: white;
    text-align: center;
    height: 500px;
    flex-direction: row;
  }
  
  
  .FlowMap-header {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .FlowMap-footer {
    background-color: white;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: right;
    color: white;
  }
  
  .FlowMap-link {
    color: #61dafb;
  }
  
  @keyframes FlowMap-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }