svg.red  {
    fill: red  !important;
}

svg.blue {
    fill:blue  !important;
}

svg.orange {
    fill:orange !important;
}

svg.green {
    fill: green !important;
}